<template>
  <div
    class="flex flex-col contact_us width-full h-full bg-white justify-items-center pb-10"
  >
    <div id="productList">
      <div>
        <h1 class="font-bold text-2xl lg:text-5xl py-10">รุ่นรถทั้งหมด</h1>
      </div>
      <PaginationHeader
        :currentPage="currentPage"
        :totalPage="totalPage"
        :perPage="perPage"
      />
      <div class="flex flex-col justify-left w-full md:w-4/5 mx-auto">
        <div class="flex flex-col justify-left lg:flex-row lg:flex-wrap">
          <ProductCard
            v-for="(item, index) in products"
            :key="index"
            :itemId="item.id"
            :filePath="item.file_path"
            :productName="item.product_name"
            :brandName="item.brand_name"
            :priceSale="item.price_sale"
            :productPrice="item.price"
          />
        </div>
      </div>

      <Pagination
        :totalItems="totalItems"
        :currentPage="currentPage"
        :totalPage="totalPage"
        :perPage="perPage"
      />
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination.vue";
import PaginationHeader from "@/views/product/list/components/PaginationHeader.vue";
import ProductCard from "@/components/ProductCard.vue";
import { getProductAll, getProductByBrand } from "@/api/product";
import { getBrandBySlug } from "@/api/brand";

export default {
  name: "Home",
  data() {
    return {
      products: [],
      totalItems: 0,
      totalPage: 8,
      perPage: 12,
      orderby: "asc",
    };
  },
  components: {
    PaginationHeader,
    Pagination,
    ProductCard,
  },
  computed: {
    currentProduct: function () {
      return this.products;
    },
    currentPage() {
      return this.$route.query.page;
    },
  },
  methods: {
    async fetchData(slug, sortBy) {
      if (slug) {
        const brand_data = await getBrandBySlug(slug);
        const brand = brand_data.data[0];
        const response = await getProductByBrand(
          slug,
          sortBy ? sortBy : this.orderby,
          this.currentPage,
          this.perPage
        );
        const data = response.data;
        this.products = data.data;
        const totalItems = data.total;
        this.totalItems = totalItems;
      } else {
        const response = await getProductAll(
          sortBy ? sortBy : this.orderby,
          this.currentPage,
          this.perPage
        );
        const data = response.data;
        this.products = data.data;
        const totalItems = data.total;
        this.totalItems = totalItems;
      }
      this.totalPage = Math.floor(this.totalItems / this.perPage);
      let remainder = this.totalItems % this.perPage;
      if (remainder > 0) this.totalPage += 1;
      return this.products;
    },
  },
  watch: {
    "$route.query"() {
      const params = new URLSearchParams(window.location.search);
      this.fetchData(params.get("brand"), params.get("sortBy"));
    },
  },
  async mounted() {
    const params = new URLSearchParams(window.location.search);
    this.fetchData(params.get("brand"), params.get("sortBy"));
  },
};
</script>
