<template>
  <div
    class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6 sm:flex-col"
  >
    <div class="flex-1 flex justify-between sm:hidden">
      <a
        href="#"
        class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
      >
        <svg
          class="h-5 w-5"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fill-rule="evenodd"
            d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
            clip-rule="evenodd"
          />
        </svg>
      </a>
      <div class="flex flex-col w-full justify-center">
        <div class="flex flex-row">
          <div class="flex w-2/5 m-auto p-auto">
            <span class="justify-right w-full">ค้นหาจาก :</span>
          </div>
          <div class="flex w-3/5 dropdown m-auto p-auto">
            <button v-if="brandSlug === 'all'" class="dropbtn">
              <div class="flex flex-row">
                <div class="flex w-1/2 m-2 p-2 w-full justify-center">
                  <span>ทั้งหมด</span>
                </div>
                <div class="flex m-auto px-4 w-auto justify-right">
                  <img src="@/assets/icon/caret-down-solid.svg" class="w-3 h-3" />
                </div>
              </div>
            </button>
            <button v-else class="dropbtn">
              <div class="flex flex-row">
                <div class="m-2 p-2 w-auto">
                  <span>{{ brandSlug }}</span>
                </div>
                <div class="m-2 p-2 w-auto">
                  <img src="@/assets/icon/caret-down-solid.svg" class="w-3 h-3" />
                </div>
              </div>
            </button>
            <div id="brandDropdown" class="flex flex-col dropdown-content">
              <button
                v-for="(brand, index) in brands"
                :key="index"
                @click="handleClick(brand.slug, selectSortBy)"
              >
                <div class="flex flex-row">
                  <div class="m-2 p-2 w-auto">
                    <span>{{ brand.brand_name }}</span>
                  </div>
                </div>
              </button>
            </div>
          </div>
        </div>
        <div class="flex flex-row">
          <div class="flex w-2/5 m-auto p-auto">
            <span class="justify-right w-full">เรียงตาม :</span>
          </div>
          <div class="flex w-3/5 dropdown m-auto p-auto">
            <button class="dropbtn">
              <div v-if="selectSortBy === 'desc'" class="flex flex-row">
                <div class="m-auto p-auto w-auto">
                  <img src="@/assets/icon/sort-down.svg" class="w-5 h-5" />
                </div>
                <div class="my-auto p-auto w-auto">
                  <span>มากไปน้อย</span>
                </div>
                <div class="m-auto p-auto w-auto">
                  <img src="@/assets/icon/caret-down-solid.svg" class="w-3 h-3" />
                </div>
              </div>
              <div v-else class="flex flex-row">
                <div class="m-auto p-auto w-auto">
                  <img src="@/assets/icon/sort-up.svg" class="w-5 h-5" />
                </div>
                <div class="my-auto p-auto w-auto">
                  <span>น้อยไปมาก</span>
                </div>
                <div class="m-auto p-auto w-auto">
                  <img src="@/assets/icon/caret-down-solid.svg" class="w-3 h-3" />
                </div>
              </div>
            </button>
            <div id="sortDropdown" class="flex flex-col dropdown-content">
              <button @click="handleClick(brandSlug, 'desc')">
                <div class="flex flex-row">
                  <div class="m-2 p-2 w-auto">
                    <img src="@/assets/icon/sort-down.svg" class="w-5 h-5" />
                  </div>
                  <div class="my-auto p-auto w-auto">
                    <span>มากไปน้อย</span>
                  </div>
                </div>
              </button>
              <button @click="handleClick(brandSlug, 'asc')">
                <div class="flex flex-row">
                  <div class="m-2 p-2 w-auto">
                    <img src="@/assets/icon/sort-up.svg" class="w-5 h-5" />
                  </div>
                  <div class="my-auto p-auto w-auto">
                    <span>น้อยไปมาก</span>
                  </div>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>

      <a
        href="#"
        class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
      >
        <svg
          class="h-5 w-5"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fill-rule="evenodd"
            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
            clip-rule="evenodd"
          />
        </svg>
      </a>
    </div>
    <div class="hidden md:flex xl:flex text-left w-full">
      <div class="flex flex-row">
        <div class="m-auto p-auto">
          <span>ค้นหาจาก :</span>
        </div>
        <div class="dropdown m-auto p-auto">
          <button v-if="brandSlug === 'all'" class="dropbtn">
            <div class="flex flex-row">
              <div class="m-2 p-2 w-auto">
                <span>ทั้งหมด</span>
              </div>
              <div class="m-2 p-2 w-auto">
                <img src="@/assets/icon/caret-down-solid.svg" class="w-3 h-3" />
              </div>
            </div>
          </button>
          <button v-else class="dropbtn">
            <div class="flex flex-row">
              <div class="m-2 p-2 w-auto">
                <span>{{ brandSlug }}</span>
              </div>
              <div class="m-2 p-2 w-auto">
                <img src="@/assets/icon/caret-down-solid.svg" class="w-3 h-3" />
              </div>
            </div>
          </button>
          <div id="brandDropdown" class="flex flex-col dropdown-content">
            <button
              v-for="(brand, index) in brands"
              :key="index"
              @click="handleClick(brand.slug,selectSortBy)"
            >
              <div class="flex flex-row">
                <div class="m-2 p-2 w-auto">
                  <span>{{ brand.brand_name }}</span>
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>
      <div class="flex flex-row">
        <div class="m-auto p-auto">
          <span>เรียงตาม :</span>
        </div>
        <div class="dropdown m-auto p-auto">
          <button class="dropbtn">
            <div v-if="selectSortBy === 'desc'" class="flex flex-row">
              <div class="m-2 p-2 w-auto">
                <img src="@/assets/icon/sort-down.svg" class="w-5 h-5" />
              </div>
              <div class="my-auto p-auto w-auto">
                <span>มากไปน้อย</span>
              </div>
              <div class="m-2 p-2 w-auto">
                <img src="@/assets/icon/caret-down-solid.svg" class="w-3 h-3" />
              </div>
            </div>
            <div v-else class="flex flex-row">
              <div class="m-2 p-2 w-auto">
                <img src="@/assets/icon/sort-up.svg" class="w-5 h-5" />
              </div>
              <div class="my-auto p-auto w-auto">
                <span>น้อยไปมาก</span>
              </div>
              <div class="m-2 p-2 w-auto">
                <img src="@/assets/icon/caret-down-solid.svg" class="w-3 h-3" />
              </div>
            </div>
          </button>
          <div id="sortDropdown" class="flex flex-col dropdown-content">
            <button @click="handleClick(brandSlug, 'desc')">
              <div class="flex flex-row">
                <div class="m-2 p-2 w-auto">
                  <img src="@/assets/icon/sort-down.svg" class="w-5 h-5" />
                </div>
                <div class="my-auto p-auto w-auto">
                  <span>มากไปน้อย</span>
                </div>
              </div>
            </button>
            <button @click="handleClick(brandSlug, 'asc')">
              <div class="flex flex-row">
                <div class="m-2 p-2 w-auto">
                  <img src="@/assets/icon/sort-up.svg" class="w-5 h-5" />
                </div>
                <div class="my-auto p-auto w-auto">
                  <span>น้อยไปมาก</span>
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>
      <div class="flex-grow w-1/3 justify-end text-right">
        <nav
          class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
          aria-label="Pagination"
        >
          <a
            href="#"
            class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
          >
            <span class="sr-only">
              <svg
                class="h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>

            <!-- Heroicon name: solid/chevron-left -->
            <svg
              class="h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                clip-rule="evenodd"
              />
            </svg>
          </a>
          <!-- Current: "z-10 bg-indigo-50 border-indigo-500 text-indigo-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50" -->
          <a
            :href="`?page=${
              page.min == page.max
                ? page.min
                : page.before
                ? page.min
                : page.max
            }`"
            aria-current="page"
            :class="`
              ${
                currentPage == page.min && currentPage == page.max
                  ? 'z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium'
                  : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium'
              }`"
            v-for="(page, index) in pages"
            :key="index"
          >{{ page.label }}</a>
          <a
            href="#"
            class="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
          >
            <span class="sr-only">
              <svg
                class="h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>
            <!-- Heroicon name: solid/chevron-right -->
            <svg
              class="h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              />
            </svg>
          </a>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import { getBrandAll } from "@/api/brand";

export default {
  data() {
    return {
      brands: [],
    };
  },
  props: {
    currentPage: {
      type: [Number],
      default: 1,
    },
    perPage: {
      type: [Number],
      default: 12,
    },
    totalPage: {
      type: [Number],
      default: 1,
    },
  },
  computed: {
    selectSortBy() {
      return this.$route.query.sortBy == null
        ? "asc"
        : this.$route.query.sortBy;
    },
    brandSlug() {
      return this.$route.query.brand == null ? "all" : this.$route.query.brand;
    },
    startAt() {
      let currentPage = this.currentPage ? parseInt(this.currentPage) : 1;
      let perPage = parseInt(this.perPage);
      let previous = (currentPage - 1) * perPage;
      return previous + 1;
    },
    endAt() {
      let currentPage = this.currentPage ? parseInt(this.currentPage) : 1;
      let perPage = parseInt(this.perPage);
      let previous = (currentPage - 1) * perPage;
      let lastId = previous + perPage;
      return lastId > this.totalItems ? this.totalItems : lastId;
    },
    pages() {
      let arr = [];
      let lastThreePage = this.totalPage - 2;
      for (let index = 1; index <= this.totalPage; index++) {
        arr.push({ label: index + "", min: index, max: index });
      }
      return arr;
    },
  },
  async mounted() {
    await this.setBrandList();
  },
  methods: {
    async setBrandList() {
      const brandResponse = await getBrandAll();
      const brands = await brandResponse.data;
      console.log("brands", brands);
      this.brands = brands;
    },
    handleClick(brandSlug, sortBy) {
      this.$router.push("/products?brand=" + brandSlug + "&sortBy=" + sortBy);
    },
    getOptionButton(sortBy) {
      if (sortBy === "asc") return "น้อยไปมาก";
      return "มากไปน้อย";
    },
  },
};
</script>

<style scoped>
/* Style The Dropdown Button */
.dropbtn {
  /* background-color: #4caf50;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer; */
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #f1f1f1;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  /* background-color: #3e8e41; */
}
</style>
